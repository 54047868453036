import { render, staticRenderFns } from "./FormAbstracts.vue?vue&type=template&id=7d2c7578&scoped=true"
import script from "./FormAbstracts.vue?vue&type=script&lang=js"
export * from "./FormAbstracts.vue?vue&type=script&lang=js"
import style0 from "./FormAbstracts.vue?vue&type=style&index=0&id=7d2c7578&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d2c7578",
  null
  
)

export default component.exports